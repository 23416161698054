
import React from 'react';
import './App.css'; 
import Weatherapp from './weatherappp';

function App() {
  return (
    <div className="app">
      <Weatherapp />
    </div>
  );
}

export default App;
